import { useQuery } from "@tanstack/react-query"
import { Button, Col, DatePicker, Form, Input, Row, Typography } from "antd"
import { FormProps, useForm, useWatch } from "antd/es/form/Form"
import { Layout } from "components/layouts"
import { CurrencyFormSelect } from "components/Selects/newSelects/CurrencyFormSelect"
import FormApiListingSelect from "components/Selects/newSelects/FormApiListingSelect"
import FormApiSelect from "components/Selects/newSelects/FormApiSelect"
import {
    PriceTypeFormSelect,
    selfCostValue,
} from "components/Selects/newSelects/PriceTypeFormSelect"
import { RegionFormSelect } from "components/Selects/newSelects/RegionFormSelect"
import { Flex } from "components/UI/Flex"
import { getUNumberValue } from "components/UNumberItem"
import { Dayjs } from "dayjs"
import api from "helpers/api"
import { useNotifications } from "hooks/useNotifications"
import { useRegion } from "hooks/useRegion"
import { pageIDs } from "pageConfig"
import { useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import { Uuid } from "types"
import { ItemsGroupEntity } from "types/api"
import { Currency } from "../CostCollector/СostCollectorModelTable"
import CalculationRulesTables from "./CaclulationRules.Tables"
import CurrencyRates from "./CalculationRules.CurrencyRates"
import CalculationRulesPrecision from "./CalculationRules.Precision"
import { useCalculationRulesStore } from "./CalculationRules.store"
import { CreateRuleData, GetRuleData } from "./CalculationRules.types"
import { tranformInputCalculationRuleData } from "./helpers"

export default function CalculationRules() {
    const [form] = useForm<CreateRuleData>()
    const history = useHistory()
    const {
        setActiveProducer,
        priceCalculationRulePriceMarginPresetLink,
        nomenclatureFilters,
        activeProducer,
        activeGroup,
        setActiveGroup,
        setInitialPriceMarginPresetLink,
        setNomenclatureFilters,
        setPriceMarginPresetLink,
        setInitialNomenclatureFilters,
    } = useCalculationRulesStore()

    const priceTypeRegion = useWatch("priceTypeRegion", form)
    const calculationTypeRegion = useWatch("calculationTypeRegion", form)
    const calculationPriceType = useWatch("calculationType", form)

    const inputCurrency = useWatch("inputCurrency", form)

    const { id } = useParams() as { id?: Uuid }

    const { activeRegion } = useRegion()

    useQuery({
        queryKey: [
            "default-currency-producer",
            calculationTypeRegion,
            activeProducer,
            activeGroup,
            form,
            inputCurrency,
        ],
        enabled:
            !!calculationTypeRegion &&
            !!activeProducer &&
            !activeGroup &&
            !inputCurrency,
        queryFn: () =>
            api
                .getTyped<{ currency: Currency } | null>(
                    "v1/economics/producer/default-currency",
                    {
                        producer: activeProducer!.value,
                        region: calculationTypeRegion,
                    }
                )
                .then((data) =>
                    data?.currency
                        ? form.setFieldValue("inputCurrency", data.currency.id)
                        : form.resetFields(["inputCurrency"])
                ),
    })

    useQuery({
        queryKey: [
            "default-currency-producer-group",
            calculationTypeRegion,
            activeProducer,
            activeGroup,
            form,
            inputCurrency,
        ],
        enabled:
            !!calculationTypeRegion &&
            !!activeProducer &&
            !!activeGroup &&
            !inputCurrency,
        queryFn: () =>
            api
                .getTyped<{ currency: Currency } | null>(
                    "v1/economics/producer/default-currency",
                    {
                        producer: activeProducer!.value,
                        region: calculationTypeRegion,
                        group: activeGroup!.value,
                    }
                )
                .then((data) =>
                    data?.currency
                        ? form.setFieldValue("inputCurrency", data.currency.id)
                        : form.resetFields(["inputCurrency"])
                ),
    })

    useQuery({
        queryKey: ["price-calculation-rule", id],
        refetchOnWindowFocus: false,
        enabled: !!id,
        queryFn: () =>
            api.getTyped<GetRuleData>("v1/price-calculation-rule/" + id),
        onSuccess: (data) => {
            const formData = tranformInputCalculationRuleData(data)
            console.log(formData)
            form.setFieldsValue(formData)
            setInitialNomenclatureFilters(
                data.priceNomenclatureFilters.map((el) => el.id)
            )
            setInitialPriceMarginPresetLink(
                data.priceCalculationRulePriceMarginPresetLink.map((el) => ({
                    id: el.priceMarginPreset.id,
                    calculationType: el.calculationType,
                    order: el.order,
                }))
            )
            setActiveGroup(
                data.itemsGroup
                    ? {
                        label: data.itemsGroup.name,
                        value: data.itemsGroup.id,
                    }
                    : null
            )
            setActiveProducer(
                data.producer
                    ? {
                        label: data.producer.name,
                        value: data.producer.id,
                    }
                    : null
            )
        },
    })

    const { showNotification, handleApiError } = useNotifications()
    const [postLoading, setPostLoading] = useState(false)

    const onFinish: FormProps["onFinish"] = (values) => {
        if (postLoading) return
        setPostLoading(true)
        const data = { ...values } as any
        data.nomenclatureFilters = nomenclatureFilters
        data.priceCalculationRulePriceMarginPresetLink =
            priceCalculationRulePriceMarginPresetLink
        const { startAt, endAt }: { startAt: Dayjs; endAt: Dayjs } = data
        data.startAt = startAt.startOf("day").toDate()
        data.endAt = endAt.endOf("day").toDate()
        if (!data.calculationType) {
            data.calculationType = null
        }
        if (data.calculationType === selfCostValue) {
            data.calculationType = null
        }
        data.outputCurrencyAmount = getUNumberValue(data.outputCurrencyAmount)
        data.outputCurrencyRate = getUNumberValue(data.outputCurrencyRate)
        data.inputCurrencyAmount = getUNumberValue(data.inputCurrencyAmount)
        data.inputCurrencyRate = getUNumberValue(data.inputCurrencyRate)
        data.value = getUNumberValue(data.value)
        const handler = id ? api.put.bind(api) : api.post.bind(api)
        const action = id ?? "create"

        handler("v1/price-calculation-rule/" + action, {}, data)
            .then(() => {
                showNotification({
                    type: "success",
                    message: "Сохранено",
                    cb() {
                        form.resetFields()
                        setPostLoading(false)
                        setActiveGroup(null)
                        setActiveProducer(null)
                        setNomenclatureFilters([])
                        setPriceMarginPresetLink([])
                        history.push("/economics/" + pageIDs.calculationRules)
                    },
                })
            })
            .catch((error: any) => {
                handleApiError(error)
                setPostLoading(false)
            })
    }

    useEffect(() => {
        if (!priceTypeRegion) {
            form.setFieldValue(
                "calculationTypeRegion",
                activeRegion?.value || null
            )
        }
    }, [form, activeRegion, calculationTypeRegion])

    useEffect(() => {
        activeProducer
            ? form.setFieldValue("producer", activeProducer?.value)
            : form.resetFields(["producer"])
    }, [activeProducer])

    useEffect(() => {
        activeGroup
            ? form.setFieldValue("itemsGroup", activeGroup?.value)
            : form.resetFields(["itemsGroup"])
    }, [activeGroup])

    return (
        <Layout.Detail pageTitle="Правила расчётов" contentTitle="">
            <Flex.Col className="fw" margin={[0, 0, 20, 0]}>
                <Form
                    form={form}
                    className="fw flex-form"
                    labelCol={{ span: 6 }}
                    labelAlign="left"
                    wrapperCol={{ span: 18 }}
                    onFinish={onFinish}
                >
                    <FormApiListingSelect
                        target="producer"
                        label="Поставщик"
                        name="producer"
                        onChange={setActiveProducer}
                        style={{ order: 1 }}
                        rules={[
                            {
                                required: true,
                                message: "Выберите поставщика",
                            },
                        ]}
                    />

                    <FormApiSelect
                        getOptionUrl={`v1/producer/${activeProducer?.value}/items-groups`}
                        transformResponse={(v: ItemsGroupEntity[]) =>
                            v.map((el) => ({
                                label: el.name,
                                value: el.id,
                            }))
                        }
                        disabled={!activeProducer}
                        label="Группа"
                        name="itemsGroup"
                        onChange={(v) => {
                            console.log(v)
                            setActiveGroup(v)
                        }}
                        style={{ order: 2 }}
                    />

                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: "Заполните поле",
                            },
                        ]}
                        name="name"
                        label="Наименование"
                        style={{ order: 5 }}
                    >
                        <Input size="large" className="form-control" />
                    </Form.Item>

                    <Form.Item
                        label="Период действия фильтра"
                        className="noMargin"
                        style={{ order: 6 }}
                    >
                        <Row
                            gutter={[20, 20]}
                            style={{ marginBottom: 20 }}
                            justify="center"
                        >
                            <Col span={11}>
                                <Form.Item
                                    rules={[
                                        {
                                            required: true,
                                            message: "Заполните поле",
                                        },
                                    ]}
                                    name="startAt"
                                    className="noMargin fw"
                                >
                                    <DatePicker size="large" className="fw" />
                                </Form.Item>
                            </Col>
                            <Col
                                span={2}
                                className="flex flex-column align-center justify-center"
                            >
                                <Typography.Text>-</Typography.Text>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    className="noMargin fw"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Заполните поле",
                                        },
                                    ]}
                                    name="endAt"
                                >
                                    <DatePicker size="large" className="fw" />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form.Item>

                    <Typography.Title
                        level={5}
                        style={{ order: 6, marginBottom: 20 }}
                    >
                        Входная валюта
                    </Typography.Title>

                    <RegionFormSelect
                        name="calculationTypeRegion"
                        label="Регион входного типа цены"
                        rules={[
                            {
                                required: true,
                                message: "Заполните поле",
                            },
                        ]}
                        style={{ order: 7 }}
                        noClear
                    />

                    <PriceTypeFormSelect
                        region={calculationTypeRegion}
                        label="Входной тип цены"
                        name={"calculationType"}
                        disabled={!calculationTypeRegion}
                        selfCost
                        rules={[
                            {
                                required: true,
                                message: "Заполните поле",
                            },
                        ]}
                        style={{ order: 8 }}
                    />

                    <CurrencyFormSelect
                        name="inputCurrency"
                        label="Входная валюта"
                        style={{ order: 9 }}
                        size="large"
                        rules={[
                            {
                                required: true,
                                message: "Заполните поле",
                            },
                        ]}
                        placeholder={
                            calculationPriceType === selfCostValue
                                ? ""
                                : "Выберите валюту"
                        }
                    />

                    <Typography.Title
                        level={5}
                        style={{ order: 10, marginBottom: 20 }}
                    >
                        Валюта экспорт
                    </Typography.Title>

                    <RegionFormSelect
                        name="priceTypeRegion"
                        label="Регион экспортного типа цены"
                        style={{ order: 10 }}
                        rules={[
                            {
                                required: true,
                                message: "Заполните поле",
                            },
                        ]}
                    />

                    <PriceTypeFormSelect
                        region={priceTypeRegion}
                        label="Экспортный тип цены"
                        name={"priceType"}
                        disabled={!priceTypeRegion}
                        rules={[
                            {
                                required: true,
                                message: "Заполните поле",
                            },
                        ]}
                        style={{ order: 11 }}
                    />

                    <CurrencyFormSelect
                        name="outputCurrency"
                        label="Экспортная валюта"
                        style={{ order: 12 }}
                        size="large"
                        rules={[
                            {
                                required: true,
                                message: "Заполните поле",
                            },
                        ]}
                    />

                    {/* <UNumberItem
                        floatRank={2}
                        useInForm={{
                            label: "Наценка в %",
                            name: "value",
                        }}
                        style={{ order: 13 }}
                    /> */}

                    <CalculationRulesPrecision />

                    <CurrencyRates />

                    <Button
                        type="primary"
                        htmlType="submit"
                        loading={postLoading}
                        disabled={postLoading}
                        style={{ order: 20 }}
                    >
                        Сохранить
                    </Button>
                </Form>

                <CalculationRulesTables />
            </Flex.Col>
        </Layout.Detail>
    )
}
