import { Listing } from "components/Listing"
import { Attributes } from "pages/Attributes"
import { Availability } from "pages/Availability"
import CollectionDetail from "pages/Collections/CollectionDetail"
import { AttributeValuesCompatibility } from "pages/Configuration/AttributeValuesCompatibility"
import { ModelsAttributesValuesBinding } from "pages/Configuration/ModelsAttributesValuesBinding"
import DeliveryTime from "pages/DeliveryTime"
import { Detail } from "pages/Detail"
import { ItemsGroupDetails } from "pages/ItemsGroup/ItemsGroupDetails"
import Kits from "pages/Kits"
import ModelAttributeValueCriteria from "pages/ModelAttributeValueCriteria"
import NomeclatureFilters from "pages/NomeclatureFilters"
import { ProducerDetails } from "pages/Producer/ProducerDetails"
import Requesites from "pages/Requesites"
import StandardKitDetail from "pages/StandardKitDetail"
import { CreateRouteData } from "./tools"

export const catalogRoutes: CreateRouteData[] = [
    {
        path: "attribute",
        title: "Свойства",
        render: () => <Listing />,
        createAndUpdate: () => <Attributes />,
    },
    {
        path: "availability-nomenclature-filter",
        title: "Фильтры доступности номенклатуры",
        render: () => <Listing />,
        createAndUpdate: () => <Availability.NomenclatureFilters />,
    },
    {
        path: "analog-nomenclature-filter",
        title: "Фильтры аналогов номенклатуры",
        render: () => <Listing />,
        createAndUpdate: () => (
            <NomeclatureFilters
                filterType="analogue"
                slug="analog-nomenclature-filter"
                noRegionSelect
                noNumericCode
            />
        ),
        permission: "availability-nomenclature-filter", // !Поменять потом
    },
    {
        path: "model-attribute",
        title: "Свойства моделей",
        render: () => <Listing />,
        createAndUpdate: () => <Detail />,
    },
    {
        path: "items-group",
        title: "Номенклатурные группы",
        render: () => <Listing />,
        createAndUpdate: () => <ItemsGroupDetails />,
        permission: "itemsGroup",
    },
    {
        path: "producer",
        title: "Поставщики",
        render: () => <Listing />,
        createAndUpdate: () => <ProducerDetails />,
    },
    {
        path: "series",
        title: "Серии",
        render: () => <Listing />,
        createAndUpdate: () => <Detail />,
    },
    {
        path: "model",
        title: "Модели",
        render: () => <Listing />,
        createAndUpdate: () => <Detail />,
    },
    {
        path: "models-attributes-values-binding",
        title: "Серии и модели",
        render: () => <ModelsAttributesValuesBinding />,
        permission: "configuration/models-attributes-values-binding",
    },
    {
        path: "attribute-values-compatibility",
        title: "Сочетания значений",
        render: () => <AttributeValuesCompatibility />,
        permission: "configuration/attribute-values-compatibility",
    },
    {
        path: "kit",
        title: "Комплекты",
        render: () => <Kits />,
    },
    {
        path: "standard-kit",
        title: "Собранные комплекты",
        render: () => <Listing />,
        update: () => <StandardKitDetail />,
    },
    {
        path: "delivery-time-filter",
        title: "Сроки изготовления",
        render: () => <Listing />,
        createAndUpdate: () => <DeliveryTime />,
    },
    {
        path: "model-attribute-value-criteria",
        title: "Стандартные размеры",
        render: () => <Listing />,
        createAndUpdate: () => <ModelAttributeValueCriteria />,
    },
    {
        path: "requisites",
        title: "Изменение реквезитов",
        render: () => <Requesites />,
    },
    {
        path: "compatible-collection",
        title: "Коллекции",
        render: () => <Listing />,
        createAndUpdate: () => <CollectionDetail />,
    },
]
