import { SyncOutlined } from "@ant-design/icons"
import { useQuery } from "@tanstack/react-query"
import { Button, Form, Select, Typography } from "antd"
import { Layout } from "components/layouts"
import QueryBuilderShowResult from "components/QueryBuilder/QueryBuilder.ShowResult"
import { NomenclatureResponse } from "components/QueryBuilder/QueryBuilderResult"
import QueryFormFIeld from "components/QueryBuilder/QueryFormFIeld"
import { Flex } from "components/UI/Flex"
import { FormLine } from "components/UI/FormLine"
import api from "helpers/api"
import withMessage, { WithShowMessage } from "hocs/withMessage"
import withQueryBuilder from "hocs/withQueryBuilder"
import { FC, useMemo, useState } from "react"
import { ListingResponse, NomenclatureItem } from "types/api"
import { GetNomenclatureRequest } from "types/interfaces"
import RequesitesLogs from "./Requesites.logs"
import {
    getRequesiteComponent,
    SetRequisitePropsRequest,
} from "./Requesites.settings"
import RequesitesFieldSelector from "./RequesitesFieldSelector"
import RequesitesValueSelector from "./RequesitesValueSelector"
import { useRequisitesStore } from "./Requisites.store"

const RequesitesPage: FC<WithShowMessage> = ({ showMessage }) => {
    const {
        selectedRequisite,
        setSelectedRequisite,
        query,
        setQuery,
        paginator,
        setPaginator,
        props,
    } = useRequisitesStore()

    const [openModal, setOpenModal] = useState(false)
    const [found, setFound] = useState<NomenclatureItem[]>([])

    const { data, isFetching } = useQuery({
        queryKey: ["v1/nomenclature/props/get-props"],
        queryFn: () =>
            api
                .getTyped<Record<string, string>>(
                    "v1/nomenclature/props/get-props",
                    {
                        count_on_page: 100,
                    }
                )
                .then((data) =>
                    Object.entries(data).map(([value, label]) => {
                        return {
                            label,
                            value,
                        }
                    })
                ),
        initialData: [],
    })

    const Component = useMemo(
        () => getRequesiteComponent(selectedRequisite),
        [selectedRequisite, getRequesiteComponent]
    )

    const {
        data: logs,
        isFetching: logsFetching,
        refetch: refetchLogs,
    } = useQuery({
        queryKey: ["listing/nomenclature/props-log"],
        queryFn: () =>
            api
                .getTyped<ListingResponse>("listing/nomenclature/props-log", {
                    count_on_page: Number.MAX_SAFE_INTEGER,
                    page: 1,
                })
                .then((response) => {
                    return response.entities
                }),
        initialData: [],
    })

    const {
        data: newFound,
        isFetching: checkResultFetching,
        refetch: refetchNomenclature,
    } = useQuery<NomenclatureResponse>({
        queryKey: [paginator.currentPage, paginator.pageSize, query],
        enabled: Boolean(
            query?.rules.length && query.rules.every((el: any) => el.value)
        ),
        refetchOnWindowFocus: false,
        queryFn: () =>
            api
                .post<GetNomenclatureRequest, NomenclatureResponse>(
                    `v1/query-builder/nomenclature/props/result`,
                    {},
                    {
                        page: paginator.currentPage,
                        countOnPage: paginator.pageSize,
                        payload: {
                            query: query,
                        },
                    }
                )
                .then(({ data }) => {
                    setPaginator(data.paginator)
                    setFound((prev) =>
                        data.paginator.currentPage > 1
                            ? [...prev, ...data.entityList]
                            : data.entityList
                    )
                    return data
                }),
    })

    const save = () => {
        const data: SetRequisitePropsRequest = {
            propsType: selectedRequisite!,
            props: props!,
            payload: {
                query: query!,
            },
        }
        api.post("v1/nomenclature/props/set-props", {}, data)
            .then(() =>
                showMessage({
                    type: "success",
                    content: "Сохранено",
                })
            )
            .catch((error) =>
                showMessage({
                    type: "error",
                    content: error.message,
                })
            )
            .finally(() => {
                refetchLogs()
            })
    }

    console.log(
        "found?.length",
        found?.length,
        "query",
        query,
        "selectedRequisite",
        selectedRequisite,
        "props",
        props
    )

    return (
        <>
            <Layout.Detail pageTitle="Изменение реквизитов">
                <Form size="large" className="fw">
                    <Flex.Col fullWidth gap={30} margin={[20, 0, 0, 0]}>
                        <FormLine label="Реквизит">
                            <Select
                                className="fw"
                                options={data}
                                loading={isFetching}
                                onChange={(v) => {
                                    console.log(v)
                                    setSelectedRequisite(v)
                                }}
                                value={selectedRequisite}
                                size="large"
                            />
                        </FormLine>
                        <FormLine label="Правило">
                            <QueryFormFIeld
                                getEntitiesURL="v1/query-builder/nomenclature/props/entities-list"
                                query={query}
                                onQueryChange={setQuery}
                                translations={{
                                    addGroup: { label: "Добавить группу" },
                                    addRule: { label: "Добавить правило" },
                                }}
                                controlElements={{
                                    valueEditor: RequesitesValueSelector,
                                    fieldSelector: RequesitesFieldSelector,
                                }}
                            />
                            <Flex.Row
                                gap={10}
                                fullWidth
                                justify="start"
                                margin={[10, 0, 0]}
                            >
                                <Button
                                    type="primary"
                                    loading={checkResultFetching}
                                    onClick={save}
                                    size="middle"
                                    disabled={
                                        !found?.length ||
                                        !query ||
                                        !selectedRequisite ||
                                        !props
                                    }
                                    // onClick={saveFilter}
                                >
                                    Применить изменения на номенклатуру
                                </Button>
                                <Button
                                    disabled={!found?.length}
                                    size="middle"
                                    onClick={() => {
                                        setOpenModal(true)
                                    }}
                                    loading={checkResultFetching}
                                >
                                    Показать номенклатуру
                                </Button>
                                <Button
                                    loading={checkResultFetching}
                                    size="middle"
                                    icon={<SyncOutlined />}
                                    onClick={() => refetchNomenclature()}
                                />
                                {!!found && !isFetching && (
                                    <Typography.Text>
                                        {paginator.totalCount
                                            ? `Найдено: ${paginator.totalCount}`
                                            : "Не найдено"}
                                    </Typography.Text>
                                )}
                            </Flex.Row>
                        </FormLine>
                        {Component && <Component />}
                        <QueryBuilderShowResult
                            openModal={openModal}
                            setOpenModal={setOpenModal}
                            loading={checkResultFetching}
                            found={found}
                            currentPage={paginator.currentPage}
                            totalPages={Math.floor(
                                paginator.totalCount / paginator.pageSize
                            )}
                            pageSize={paginator.pageSize}
                            nextPage={() =>
                                setPaginator({
                                    ...paginator,
                                    currentPage: paginator.currentPage + 1,
                                })
                            }
                        />
                        <RequesitesLogs logs={logs} loading={logsFetching} />
                    </Flex.Col>
                </Form>
            </Layout.Detail>
        </>
    )
}

export default withQueryBuilder(withMessage(RequesitesPage))
