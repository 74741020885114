import { useQuery } from "@tanstack/react-query"
import { Layout } from "components/layouts"
import { SelectEntity } from "components/Selects/types"
import { AsyncSelect } from "components/UI"
import api from "helpers/api"
import { useNotifications } from "hooks/useNotifications"
import { useSaveOrAcceptProps } from "hooks/useSaveOrAccept"
import { Attribute } from "pages/CostCollector/CostCollectorAttributes/CostCollectorAttributes.types"
import { useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import { Uuid } from "types"

type CrudData = {
    attributes: Attribute[]
    id?: Uuid
}

export const AttributeCombination = () => {
    const { id } = useParams() as { id?: Uuid }

    const [attributes, setAttributes] = useState<SelectEntity[]>([])
    const history = useHistory()
    const { handleApiError, showNotification } = useNotifications()
    const { withLoading, isLoading } = useSaveOrAcceptProps()

    const { isFetching, refetch } = useQuery<CrudData>({
        queryKey: [`reference/attribute-combination`, id],
        enabled: !!id,
        queryFn: () => api.getTyped(`reference/attribute-combination/${id}`),
        onSuccess: (data) =>
            setAttributes(
                data.attributes.map((el) => ({
                    label: el.name,
                    value: el.id,
                }))
            ),
    })

    const postHandler = (redirect?: boolean) => {
        const action = id ? api.put.bind(api) : api.post.bind(api)
        const postfix = id ? `/${id}` : ""
        return action(
            "reference/attribute-combination" + postfix,
            {},
            {
                attributes: attributes.map((el) => el.value),
            }
        )
            .then(() => {
                showNotification({
                    type: "success",
                    message: "Сохранено",
                    cb() {
                        if (redirect) {
                            history.push("/economic/attribute-combination")
                        } else {
                            refetch()
                        }
                    },
                })
            })
            .catch(handleApiError)
    }

    const onSave = (value: "save" | "accept") =>
        withLoading(postHandler, value, 1000)

    return (
        <Layout.Detail
            pageTitle="Установка порядка просчета на сочетания свойств"
            onAcceptProps={{
                onAccept: () => onSave("accept")(false),
                isLoading: isLoading === "accept",
            }}
            onSaveProps={{
                onSave: () => onSave("save")(true),
                isLoading: isLoading === "save",
            }}
        >
            <AsyncSelect
                isMulti
                onChange={(e: any) => {
                    setAttributes(e)
                }}
                isLoading={isFetching}
                value={attributes}
                dataUrl="attribute"
                isClearable
            />
        </Layout.Detail>
    )
}
