import { useQuery } from "@tanstack/react-query"
import { Form, Input } from "antd"
import { FormProps, useForm } from "antd/es/form/Form"
import { Layout } from "components/layouts"
import FieldSelector from "components/QueryBuilder/FieldSelector"
import QueryBuilderResult from "components/QueryBuilder/QueryBuilderResult"
import QueryFormField from "components/QueryBuilder/QueryFormFIeld"
import ValuesAsyncSelector from "components/QueryBuilder/ValueAsyncSelector"
import { RegionFormSelect } from "components/Selects/newSelects/RegionFormSelect"
import { Flex } from "components/UI/Flex"
import api from "helpers/api"
import { formLayout } from "helpers/formLayout"
import withMessage, { WithShowMessage } from "hocs/withMessage"
import { usePageId } from "hooks/usePageId"
import { useCallback, useRef, useState } from "react"
import { RuleGroupType } from "react-querybuilder"
import { useHistory } from "react-router-dom"
import { Uuid } from "types"
import { Country } from "types/api"

type CollectionData = {
    name: string
    payload: {
        query: RuleGroupType<any>
        id?: Uuid
    }
    scope?: string | string[]
}

type GetCollectionResponse = {
    collection: {
        id: Uuid
        name: string
        query?: {
            id: Uuid
            query?: RuleGroupType<any>
        }
        scope?: Country[]
    }
}

function CollectionDetail({ showMessage }: WithShowMessage) {
    const [query, setQuery] = useState<RuleGroupType<any>>()
    const history = useHistory()
    const [form] = useForm<{
        name: string
        scope: string | string[]
        id?: Uuid
        query?: RuleGroupType<any>
    }>()
    const redirectRef = useRef<boolean>(true)
    const { id } = usePageId()

    useQuery<GetCollectionResponse>({
        queryKey: [id, "get-collection"],
        enabled: !!id,
        queryFn: () =>
            api.getTyped<GetCollectionResponse>(
                `v1/compatible-collection/${id}`
            ),
        onSuccess: (data) => {
            const { collection } = data
            form.setFieldsValue({
                name: collection.name,
                query: collection.query,
                scope: collection.scope
                    ? collection.scope.map((el) => el.id)
                    : undefined,
            })
            setQuery(
                collection?.query?.query
                    ? {
                          ...collection.query?.query,
                          rules: collection.query.query.rules ?? [],
                      }
                    : undefined
            )
        },
    })

    const mutations = {
        create: (requestData: CollectionData) =>
            api.post("v1/compatible-collection/create", {}, requestData),
        update: (requestData: CollectionData) =>
            api.put(`v1/compatible-collection/update/${id}`, {}, requestData),
    }

    const onFinish: FormProps["onFinish"] = (values) => {
        if (!query) return
        const requestData: CollectionData = {
            payload: {
                query: query,
                id: id,
            },
            ...values,
        }

        const mutatationKey: keyof typeof mutations = id ? "update" : "create"
        const mutate = mutations[mutatationKey]
        mutate(requestData)
            .then(() => {
                if (redirectRef.current) {
                    setTimeout(() => {
                        history.push(`/catalog/compatible-collection`)
                    }, 3000)
                }
                showMessage({
                    type: "success",
                    content: "Успешно",
                })
            })
            .catch((error) => {
                showMessage({
                    type: "error",
                    content: error.message,
                })
            })
    }

    const onSave = useCallback((redirect: boolean) => {
        redirectRef.current = redirect
        form.submit()
    }, [])

    return (
        <Layout.Detail
            pageTitle={id ? "Редактировать коллекцию" : "Создать коллекцию"}
            onSaveProps={{
                onSave: () => onSave(true),
                disabled: !query,
            }}
            onAcceptProps={{
                onAccept: () => onSave(false),
                disabled: !query,
            }}
        >
            <Flex.Col gap={10} fullWidth>
                <Form
                    form={form}
                    className="fw"
                    {...formLayout}
                    onFinish={onFinish}
                >
                    <Flex.Col gap={10} fullWidth>
                        <Form.Item
                            label="Название"
                            className="fw"
                            name={"name"}
                        >
                            <Input size="large" className="fw" />
                        </Form.Item>

                        <RegionFormSelect
                            className="fw"
                            isMultiple
                            name="scope"
                        />

                        <Form.Item label="Фильтр" name={"query"} className="fw">
                            <QueryFormField
                                query={query}
                                getEntitiesURL="v1/query-builder/compatible-collection/entities-list"
                                controlElements={{
                                    valueEditor: (props) => (
                                        <ValuesAsyncSelector
                                            optionUrl="v1/query-builder/compatible-collection/values-list"
                                            {...props}
                                        />
                                    ),
                                    fieldSelector: FieldSelector,
                                }}
                                onQueryChange={(q) => {
                                    setQuery({
                                        ...q,
                                        rules: q?.rules ?? [],
                                    })
                                }}
                                translations={{
                                    addGroup: { label: "Добавить группу" },
                                    addRule: { label: "Добавить правило" },
                                }}
                            />
                        </Form.Item>
                        <Flex.Row fullWidth gap={20}>
                            <QueryBuilderResult
                                dataUrl="v1/query-builder/price-nomenclature-filter/result"
                                query={query}
                            />
                        </Flex.Row>
                    </Flex.Col>
                </Form>
            </Flex.Col>
        </Layout.Detail>
    )
}

export default withMessage(CollectionDetail)
