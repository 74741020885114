import clsx from "clsx"
import { usePageData } from "hooks/usePageData"
import { usePermission } from "hooks/usePermission"
import murmurhash from "murmurhash"
import React, { useCallback, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, NavLink } from "react-router-dom"
import { routes } from "routes/routes"
import { RootState } from "store/types"
import { SAVE_FOR_TEMP } from "../../store/bufferTemp/actions"
import { RESET_COMPARE_TYPE } from "../../store/compareType/actions"
import { RESET_ERROR } from "../../store/errors/actions"
import { RESET_CURRENT_VALUES } from "../../store/fields/actions"
import { SET_IS_SEARCH_RESULT } from "../../store/isSearchResult/actions"
import { CLEAR_LISTING } from "../../store/listing/actions"
import { MenuItemContainerData, MenuItemData } from "./settings"

interface MenuProps {
    credentials: string[]
}

interface MenuItemContainerProps {
    data: MenuItemContainerData
    credentials: string[]
}
type MenuItemProps = {
    data: MenuItemData
    credentials: string[]
}

const MenuItem = ({ data }: MenuItemProps) => {
    const { slug, title, folder } = data
    const { routeBasePath } = usePageData()
    const { checkCredentials } = usePermission()

    const dispatch = useDispatch()

    const resetHandler = useCallback(() => {
        dispatch(RESET_ERROR())
        dispatch(CLEAR_LISTING())
        dispatch(RESET_CURRENT_VALUES())
        dispatch(RESET_COMPARE_TYPE({}))
        dispatch(SAVE_FOR_TEMP({ filter: "", attributes: "", q: "" }))
        dispatch(SET_IS_SEARCH_RESULT(false))
    }, [])

    const itemPathName = [folder, slug].filter(Boolean).join("/")

    const isActive = routeBasePath === itemPathName

    return checkCredentials(slug, "view") ? (
        <li>
            <NavLink
                onClick={
                    routeBasePath !== itemPathName
                        ? () => {
                              resetHandler()
                          }
                        : () => {}
                }
                key={routeBasePath}
                to={"/" + itemPathName}
                isActive={() => false}
                className={clsx({
                    "link-white": true,
                    "btn-primary": isActive,
                    rounded: true,
                })}
            >
                {title}
            </NavLink>
        </li>
    ) : null
}

const MenuItemContainer = ({ data }: MenuItemContainerProps) => {
    const { items, title } = data
    const { checkCredentials } = usePermission()
    const collapseId = useMemo(
        () => "collapse_" + murmurhash.v3(title),
        [title]
    )

    const user = useSelector((state: RootState) => state.user.user)
    const credentials = user?.credentials ?? []

    const { folder } = usePageData()

    const show = items.some((el) => checkCredentials(el.slug, "view"))

    const isExpanded = data.items.some((el) => {
        return el.folder === folder
    })

    return (
        <li className="mb-2">
            {show ? (
                <>
                    <button
                        className="btn btn-toggle align-items-center rounded collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target={`#${collapseId}`}
                        aria-expanded="false"
                    >
                        {title}
                    </button>
                    <div
                        className={"collapse" + (isExpanded ? " show" : "")}
                        id={collapseId}
                    >
                        <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                            {items.map((el, idx) => (
                                <MenuItem
                                    key={idx}
                                    data={el}
                                    credentials={credentials}
                                />
                            ))}
                        </ul>
                    </div>
                </>
            ) : null}
        </li>
    )
}

const Menu: React.FC<MenuProps> = ({ credentials }) => {
    return (
        <div
            key={"menu-" + credentials.length}
            className="flex-shrink-0 p-3 sidebar"
        >
            <Link
                to="/"
                className="d-flex align-items-center pb-3 mb-3 link-white text-decoration-none border-bottom"
            >
                <span className="fs-6 fw-semibold sidebar-txt">УТК</span>
            </Link>
            <ul className="list-unstyled ps-0">
                {routes.map((folder, idx) => {
                    const data: MenuItemContainerData = {
                        title: folder.title,
                        items: folder.routes
                            .filter((routeData) => !routeData.dontShowInMenu)
                            .map((el) => ({
                                slug: el.path,
                                title: el.title,
                                folder: folder.folder ?? "",
                            })),
                    }
                    return (
                        <MenuItemContainer
                            data={data}
                            key={idx}
                            credentials={credentials}
                        />
                    )
                })}
            </ul>
        </div>
    )
}

export default Menu
